
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
import { default as GridContent } from '@/components/base-layouts/grid-content/index.vue';
import { Gauge, Pie, TagCloud, WaterWave } from './components/charts';
import { queryTags } from '@/api/dashboard/monitor';
import ActiveChart from './components/active-chart/index.vue';

import numeral from 'numeral';
import { useFetchData } from '@/utils/hooks/useFetchData';

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { context } = useFetchData(
      () => {
        return queryTags().then(res => {
          return {
            data: res.list,
            total: 100,
          };
        });
      },
      {
        loading: false,
      },
    );

    return {
      t,
      numeral,

      context,
    };
  },
  components: {
    GridContent,
    ActiveChart,
    Gauge,
    Pie,
    TagCloud,
    WaterWave,
  },
});
